import React from 'react';
import { Flex, Text } from 'rebass/styled-components';

export const TextLogo = ({ children }) => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <Text
        fontFamily="var(--font-mono)"
        fontSize={['10px', '12px', '14px']}
        lineHeight={['13px', '15px', '17px']}
        sx={{
          letterSpacing: '0.05em',
          textTransform: 'uppercase',
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </Text>
    </Flex>
  );
};
