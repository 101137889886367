import { BuzzLogoIdEnum } from './BuzzLogoIdEnum';
import TwoFactorAuth from './components/TwoFactorAuth';
import AesEncryption from './components/AesEncryption';

export const logos = {
  [BuzzLogoIdEnum.AITO_COLOR]: {
    title: 'AITO',
    src: 'https://ucarecdn.com/66c6a4c6-a727-4625-8457-0a878b01dc42/aitocolor.svg',
  },
  [BuzzLogoIdEnum.AITO_BW]: {
    title: 'AITO',
    src: 'https://ucarecdn.com/95bf9c64-d08c-46e2-a685-0e2a39614c24/aitobw.svg',
  },
  [BuzzLogoIdEnum.BUFFER_BW]: {
    title: 'Buffer',
    src: 'https://ucarecdn.com/d470fc9a-2b51-4520-b5ce-60c48868596a/bufferbw.svg',
  },
  [BuzzLogoIdEnum.BUFFER_COLOR]: {
    title: 'Buffer',
    src: 'https://ucarecdn.com/615c5a19-707d-401f-881a-63f622b5cf99/buffercolor.svg',
  },
  [BuzzLogoIdEnum.SUNDANCE_COLOR]: {
    title: 'Sundance',
    src: 'https://ucarecdn.com/5fad9d52-9374-4b3d-baf9-f8958a5d6e06/sundancecolor.svg',
  },
  [BuzzLogoIdEnum.SUNDANCE_BW]: {
    title: 'Sundance',
    src: 'https://ucarecdn.com/667c423a-cfb5-481f-a57d-fe406b5607eb/sundancebw.svg',
  },
  [BuzzLogoIdEnum.CHATRA]: {
    title: 'Chatra',
    src: 'https://ucarecdn.com/44d49059-6c31-4c94-a2c0-271d06841613/chatra.svg',
  },
  [BuzzLogoIdEnum.CHATRA_COLOR]: {
    title: 'Chatra',
    src: 'https://ucarecdn.com/cb83ebfa-57ff-4df4-aea0-b846a160f98e/chatracolor.svg',
  },
  [BuzzLogoIdEnum.IRELAND_COLOR]: {
    title: 'Government of Ireland',
    src: 'https://ucarecdn.com/bbd78271-fb09-4d60-b4f1-edc77e0d2304/irelandgovernmentcolor.svg',
  },
  [BuzzLogoIdEnum.IRELAND_BW]: {
    title: 'Government of Ireland',
    src: 'https://ucarecdn.com/b89481f6-518d-4b2e-9a8d-738174e779d5/irelandgovernmentbw.svg',
  },
  [BuzzLogoIdEnum.PHILIPPINES_COLOR]: {
    title: 'Philippines',
    src: 'https://ucarecdn.com/873f5187-a32c-44e5-aa58-e16e6b694939/plilippinesgovernmentcolor.svg',
  },
  [BuzzLogoIdEnum.PHILIPPINES_BW]: {
    title: 'Philippines',
    src: 'https://ucarecdn.com/aa8aa9a2-99c9-4719-a16a-58f742219688/plilippinesgovernmentbw.svg',
  },
  [BuzzLogoIdEnum.BERKELEY_BW]: {
    title: 'Berkeley',
    src: 'https://ucarecdn.com/4eef62a3-e2fc-449d-8d8d-7883a6b221fe/berkeleybw.svg',
  },
  [BuzzLogoIdEnum.BERKELEY_COLOR]: {
    title: 'Berkeley',
    src: 'https://ucarecdn.com/497b58f2-09eb-4d5d-9b2b-0c44dd99f6d1/berkeleycolor.svg',
  },
  [BuzzLogoIdEnum.BRITISH_COUNCIL_COLOR]: {
    title: 'British Council',
    src: 'https://ucarecdn.com/c3a70941-f14b-447f-9919-4fe5f42a4205/britishcouncilcolor.svg',
  },
  [BuzzLogoIdEnum.BRITISH_COUNCIL_BW]: {
    title: 'British Council',
    src: 'https://ucarecdn.com/cdcabfef-b22c-483d-9abf-c9664397b07e/britishcouncilbw.svg',
  },
  [BuzzLogoIdEnum.CARDANO_COLOR]: {
    title: 'Cardano',
    src: 'https://ucarecdn.com/0e2a9b6a-19e5-42b4-ad91-dca14c609d84/cardanocolor.svg',
  },
  [BuzzLogoIdEnum.CARDANO_BW]: {
    title: 'Cardano',
    src: 'https://ucarecdn.com/95ead53a-d8b4-47c9-be45-75461cf2a7af/cardanobw.svg',
  },
  [BuzzLogoIdEnum.CAMPAIGNMONITOR_BW]: {
    title: 'Сampaignmonitor',
    src: 'https://ucarecdn.com/3910f93f-cc69-4185-8fed-3b67aebd29cc/campaignmonitorbw.svg',
  },
  [BuzzLogoIdEnum.CAMPAIGNMONITOR_COLOR]: {
    title: 'Сampaignmonitor',
    src: 'https://ucarecdn.com/9aedc0ea-50f2-4d9b-a633-a2ef178235c2/campaignmonitorcolor.svg',
  },
  [BuzzLogoIdEnum.CISCO_BW]: {
    title: 'Cisco',
    src: 'https://ucarecdn.com/1f3957c6-d418-4b5e-8047-a356e94a3e7b/ciscobw.svg',
  },
  [BuzzLogoIdEnum.CISCO_COLOR]: {
    title: 'Cisco',
    src: 'https://ucarecdn.com/1531908c-5abe-4e70-ae08-b113322b5e04/ciscocolor.svg',
  },
  [BuzzLogoIdEnum.CODEMENTOR_BW]: {
    title: 'Codementor',
    src: 'https://ucarecdn.com/02a3e3e2-aa0f-431b-9779-b31de5155b7b/codementorbw.svg',
  },
  [BuzzLogoIdEnum.CODEMENTOR_COLOR]: {
    title: 'Codementor',
    src: 'https://ucarecdn.com/7e6e13e2-2828-46c2-8deb-0e0a4005301f/codementorcolor.svg',
  },
  [BuzzLogoIdEnum.FITBIT_BW]: {
    title: 'Fitbit',
    src: 'https://ucarecdn.com/55533001-4e10-4a48-8de0-581a59669a5c/fitbitbw.svg',
  },
  [BuzzLogoIdEnum.FITBIT_COLOR]: {
    title: 'Fitbit',
    src: 'https://ucarecdn.com/ec89cb10-83a7-4fc8-a4f6-97ee910e1364/fitbitcolor.svg',
  },
  [BuzzLogoIdEnum.FRAMEFOX_BW]: {
    title: 'Framefox',
    src: 'https://ucarecdn.com/5921c915-0813-4add-826e-eecc2811189d/framefoxbw.svg',
  },
  [BuzzLogoIdEnum.FRAMEFOX_COLOR]: {
    title: 'Framefox',
    src: 'https://ucarecdn.com/0a171adf-b845-4b13-aec7-bfa1c966d755/framefoxcolor.svg',
  },
  [BuzzLogoIdEnum.INDEED_BW]: {
    title: 'Indeed',
    offset: [0, 0, 10, 0],
    src: 'https://ucarecdn.com/e1a2f053-608a-4407-8043-2f25db041de9/indeedbw.svg',
  },
  [BuzzLogoIdEnum.INDEED_COLOR]: {
    title: 'Indeed',
    offset: [0, 0, 10, 0],
    src: 'https://ucarecdn.com/16f6634a-9016-4184-9505-0991a261b31d/indeedcolor.svg',
  },
  [BuzzLogoIdEnum.INSTAMAG_BW]: {
    title: 'Instamag',
    offset: [7, 0, 0, 4],
    src: 'https://ucarecdn.com/00a9fd1a-e9ad-4c8c-8872-9e251a41ba50/instamagbw.svg',
  },
  [BuzzLogoIdEnum.INSTAMAG_COLOR]: {
    title: 'Instamag',
    offset: [7, 0, 0, 4],
    src: 'https://ucarecdn.com/69ea19c6-77f1-4244-afdf-a85a7579bbd7/instamagcolor.svg',
  },
  [BuzzLogoIdEnum.INSTAMAG_MONO]: {
    title: 'Instamag',
    offset: [7, 0, 0, 4],
    src: 'https://ucarecdn.com/bb40347a-e353-4b89-818e-4de246b2b560/instamagmono.svg',
  },
  [BuzzLogoIdEnum.LOREAL]: {
    title: 'L’Oreal',
    src: 'https://ucarecdn.com/57cd31af-3163-496e-94f1-b79fd469a490/loreal.svg',
  },
  [BuzzLogoIdEnum.MOZILLA]: {
    title: 'Mozilla',
    src: 'https://ucarecdn.com/aac7854f-3fe2-47c1-b23d-61d9d5ae4408/mozilla.svg',
  },
  [BuzzLogoIdEnum.PANDADOC_BW]: {
    title: 'PandaDoc',
    src: 'https://ucarecdn.com/5fa0954d-e667-493d-a89c-2a8985d6c019/pandadocbw.svg',
  },
  [BuzzLogoIdEnum.PANDADOC_COLOR]: {
    title: 'PandaDoc',
    src: 'https://ucarecdn.com/01fa39cc-32f5-4969-b32b-a5325742caaf/pandadoccolor.svg',
  },
  [BuzzLogoIdEnum.PREZLY_BW]: {
    title: 'Prezly',
    offset: [3, 0, 0, 0],
    src: 'https://ucarecdn.com/b2624f76-62e5-4ef4-b69e-6dc8caa10791/prezlybw.svg',
  },
  [BuzzLogoIdEnum.PREZLY_COLOR]: {
    title: 'Prezly',
    offset: [3, 0, 0, 0],
    src: 'https://ucarecdn.com/d5d57b44-4541-4834-97c9-3f4b94ce0ace/prezlycolor.svg',
  },
  [BuzzLogoIdEnum.SAMSONITE_BW]: {
    title: 'Samsonite',
    src: 'https://ucarecdn.com/1f6bfd6a-7bae-4902-948b-de59bd483c42/samsonitebw.svg',
  },
  [BuzzLogoIdEnum.SAMSONITE_COLOR]: {
    title: 'Samsonite',
    src: 'https://ucarecdn.com/be957448-0994-4a4e-a5c1-fc72cfebc66f/samsonitecolor.svg',
  },
  [BuzzLogoIdEnum.SFSTATE_BW]: {
    title: 'San Francisco State University',
    src: 'https://ucarecdn.com/4fea4430-d395-4e91-a547-47deb1571c0a/sfstatebw.svg',
  },
  [BuzzLogoIdEnum.SFSTATE_COLOR]: {
    title: 'San Francisco State University',
    src: 'https://ucarecdn.com/80221127-067e-4252-b9f2-cdc792ed2624/sfstatecolor.svg',
  },
  [BuzzLogoIdEnum.SHOGUN_BW]: {
    title: 'Shogun',
    src: 'https://ucarecdn.com/cbb0ed6f-5963-4423-bbf8-25a0b9293889/shogunbw.svg',
  },
  [BuzzLogoIdEnum.SHOGUN_COLOR]: {
    title: 'Shogun',
    src: 'https://ucarecdn.com/dbd21f98-c8fa-4b9d-bdbf-0bbdbda1e270/shoguncolor.svg',
  },
  [BuzzLogoIdEnum.SNUGGPRO_BW]: {
    title: 'SnuggPro',
    offset: [0, 0, 7, 0],
    src: 'https://ucarecdn.com/18045cbf-732b-4124-b778-3870103de8a2/snuggprobw.svg',
  },
  [BuzzLogoIdEnum.SNUGGPRO_COLOR]: {
    title: 'SnuggPro',
    offset: [0, 0, 7, 0],
    src: 'https://ucarecdn.com/3f4b9377-42d0-410a-b3e5-f37098c1ef39/snuggprocolor.svg',
  },
  [BuzzLogoIdEnum.SOUNDCLOUD_BW]: {
    title: 'Soundcloud',
    src: 'https://ucarecdn.com/5ab65800-4e4b-4463-9427-2947cce0ca19/soundcloudbw.svg',
  },
  [BuzzLogoIdEnum.SOUNDCLOUD_COLOR]: {
    title: 'Soundcloud',
    src: 'https://ucarecdn.com/88f6aa6a-281b-487c-b7b7-1275f8a3a84b/soundcloudcolor.svg',
  },
  [BuzzLogoIdEnum.SPLITMETRICS_BW]: {
    title: 'SplitMetrics',
    src: 'https://ucarecdn.com/9a1904ac-0e9f-423a-9eef-f9dee442ea51/splitmetricsbw.svg',
  },
  [BuzzLogoIdEnum.SPLITMETRICS_COLOR]: {
    title: 'SplitMetrics',
    src: 'https://ucarecdn.com/ef4e250a-b291-43b0-98f0-c2d03362e49d/splitmetricscolor.svg',
  },
  [BuzzLogoIdEnum.STACKSHARE_BW]: {
    title: 'Stackshare',
    src: 'https://ucarecdn.com/106db318-bd00-45fc-9ff6-34b932b85395/stacksharebw.svg',
  },
  [BuzzLogoIdEnum.STACKSHARE_COLOR]: {
    title: 'Stackshare',
    src: 'https://ucarecdn.com/0d5b08d6-c9ac-4384-943f-e1129fa55d16/stacksharecolor.svg',
  },
  [BuzzLogoIdEnum.TEOPIKI]: {
    title: 'Teopiki',
    src: 'https://ucarecdn.com/be0515e9-bb4f-4878-99aa-0cb0f55a5a35/teopiki.svg',
  },
  [BuzzLogoIdEnum.TIMEPAD_COLOR]: {
    title: 'Timepad',
    src: 'https://ucarecdn.com/7f5f4b2f-4dc1-4bc1-a0f6-4a751815cee7/timepadcolor.svg',
  },
  [BuzzLogoIdEnum.TIMEPAD]: {
    title: 'Timepad',
    src: 'https://ucarecdn.com/c222ed17-462b-40da-b862-d393596f8b03/timepad.svg',
  },
  [BuzzLogoIdEnum.TOTANGO_BW]: {
    title: 'Totango',
    src: 'https://ucarecdn.com/ca66ac3d-3554-4491-acf8-15a632ad9932/totangobw.svg',
  },
  [BuzzLogoIdEnum.TOTANGO_COLOR]: {
    title: 'Totango',
    src: 'https://ucarecdn.com/d2691372-4a91-4391-bc1d-ef440573fa2f/totangocolor.svg',
  },
  [BuzzLogoIdEnum.YCOMBINATOR_BW]: {
    title: 'YCombinator',
    src: 'https://ucarecdn.com/fa1eff9e-45cc-4dcc-8289-29077e3c501e/ycombinatorbw.svg',
  },
  [BuzzLogoIdEnum.YCOMBINATOR_COLOR]: {
    title: 'YCombinator',
    src: 'https://ucarecdn.com/8cadb1bb-9f56-4cb6-9891-115d084f46c1/ycombinatorcolor.svg',
  },
  [BuzzLogoIdEnum.UNIDESK_BW]: {
    title: 'Unidesk',
    src: 'https://ucarecdn.com/7c1de12b-725e-410c-a8ae-0150716dad1a/unideskbw.svg',
  },
  [BuzzLogoIdEnum.UNIDESK_COLOR]: {
    title: 'Unidesk',
    src: 'https://ucarecdn.com/32a02242-c5b0-4dbd-8d08-8b22e2f2ba8c/unideskcolor.svg',
  },
  [BuzzLogoIdEnum.UNIVERSAL]: {
    title: 'Universal',
    src: 'https://ucarecdn.com/dba5c289-e5e0-457d-8163-74197242ee91/universal.svg',
  },
  [BuzzLogoIdEnum.UPLOADCARE]: {
    title: 'Uploadcare',
    src: 'https://ucarecdn.com/3a81f66b-39c0-421d-89f1-0bb48aada6fb/uploadcare.svg',
  },
  [BuzzLogoIdEnum.UPLOADCARE_BW]: {
    title: 'Uploadcare',
    src: 'https://ucarecdn.com/d0b0ede8-6754-4bfc-8a49-3b8e212a1082/uploadcarebw.svg',
  },
  [BuzzLogoIdEnum.WEBFLOW]: {
    title: 'Webflow',
    src: 'https://ucarecdn.com/d57a6533-cb09-4739-b26f-9e7e616fff0b/webflow.svg',
  },
  [BuzzLogoIdEnum.ZILLOW_BW]: {
    title: 'Zillow',
    offset: [2, 0, 0, 0],
    src: 'https://ucarecdn.com/c12c1586-d806-4abe-b2d6-3c79c4b50237/zillowbw.svg',
  },
  [BuzzLogoIdEnum.ZILLOW_COLOR]: {
    title: 'Zillow',
    offset: [2, 0, 0, 0],
    src: 'https://ucarecdn.com/42035305-a49a-4920-a0bb-51ea503f0a7f/zillowcolor.svg',
  },
  [BuzzLogoIdEnum.LAPHIL_COLOR]: {
    title: 'La Phil',
    src: 'https://ucarecdn.com/8ae5db2a-7d8a-402a-949f-5739c1f2b489/laphilcolor.svg',
  },
  [BuzzLogoIdEnum.LAPHIL]: {
    title: 'La Phil',
    src: 'https://ucarecdn.com/addc41cb-37ef-4d4c-8f06-cb11a78b9e6b/laphil.svg',
  },
  [BuzzLogoIdEnum.HIPAA]: {
    title: 'HIPAA',
    src: 'https://ucarecdn.com/3ae052a3-7016-4c15-b3de-1a4bd97d2b20/hipaa.svg',
  },
  [BuzzLogoIdEnum.GDPR]: {
    title: 'GDPR',
    src: 'https://ucarecdn.com/89bd5bdc-fb6e-4faa-b4dd-80fd55c3f0fb/gdpr.svg',
  },
  [BuzzLogoIdEnum.PRIVACY_SHIELD]: {
    title: 'Privacy Shield',
    src: 'https://ucarecdn.com/824f0267-67be-4192-8204-0a49d079b0db/privacyshield.svg',
  },
  [BuzzLogoIdEnum.TWO_FA]: {
    title: 'Two-factor authentication',
    component: TwoFactorAuth,
  },
  [BuzzLogoIdEnum.AES_ENCRYPTION]: {
    title: 'Two-factor authentication',
    component: AesEncryption,
  },
  [BuzzLogoIdEnum.ZEPHYR_BW]: {
    title: 'Zephyr',
    src: 'https://ucarecdn.com/188f8fa8-8189-4296-962e-cb60c924d108/zephyrbw.svg',
  },
  [BuzzLogoIdEnum.ZEPHYR_COLOR]: {
    title: 'Zephyr',
    src: 'https://ucarecdn.com/6189a5c7-0430-4eba-860d-019db70c4497/zephyrcolor.svg',
  },
  [BuzzLogoIdEnum.CELEARNING_BW]: {
    title: 'CE-Learning Systems',
    src: 'https://ucarecdn.com/0e08c181-3481-4d5a-ad89-f12482accf09/celearningbw.svg',
  },
  [BuzzLogoIdEnum.CELEARNING_COLOR]: {
    title: 'CE-Learning Systems',
    src: 'https://ucarecdn.com/c493578b-7254-4489-9c6f-05d6d1d47315/celearningcolor.svg',
  },
  [BuzzLogoIdEnum.CINDICATOR]: {
    title: 'Cindicator',
    src: 'https://ucarecdn.com/f8cbe31e-7629-4669-b10b-9e235be11553/cindicator.svg',
  },
  [BuzzLogoIdEnum.ENGELVOLKERS_BW]: {
    title: 'Engel & Völkers',
    src: 'https://ucarecdn.com/aa9cb467-be9c-4138-ab25-e1f41462cac0/engelvolkersbw.svg',
  },
  [BuzzLogoIdEnum.ENGELVOLKERS_COLOR]: {
    title: 'Engel & Völkers',
    src: 'https://ucarecdn.com/87fbdf0e-59c5-42b1-8801-6f5b891b27e7/engelvolkerscolor.svg',
  },
  [BuzzLogoIdEnum.LMBD]: {
    title: 'Lambada Market',
    src: 'https://ucarecdn.com/4e1c98ee-c037-4d3c-8a5e-6b992ff99c7b/lmbd.svg',
  },
  [BuzzLogoIdEnum.METIGY_BW]: {
    title: 'Metigy',
    src: 'https://ucarecdn.com/3c5f9303-da37-495c-883b-3065f95ad6a0/metigybw.svg',
  },
  [BuzzLogoIdEnum.METIGY_COLOR]: {
    title: 'Metigy',
    src: 'https://ucarecdn.com/762a8860-2093-4e76-96c1-bb22f5ef56e1/metigycolor.svg',
  },
  [BuzzLogoIdEnum.MOODZER_BW]: {
    title: 'Moodzer',
    src: 'https://ucarecdn.com/5ae9f9b1-33a0-4d15-9726-2554fdc6cabd/moodzerbw.svg',
  },
  [BuzzLogoIdEnum.MOODZER_COLOR]: {
    title: 'Moodzer',
    src: 'https://ucarecdn.com/54cad7b4-2eb4-44ce-820d-bbe5693fa43e/moodzercolor.svg',
  },
  [BuzzLogoIdEnum.MIGHTYCAUSE_BW]: {
    title: 'Mightycause',
    src: 'https://ucarecdn.com/b2c55048-c787-417c-9503-65007e7760d7/mightycausebw.svg',
  },
  [BuzzLogoIdEnum.MIGHTYCAUSE_COLOR]: {
    title: 'Mightycause',
    src: 'https://ucarecdn.com/e2660a24-aadc-41b7-8f3e-238afe60ee7e/mightycausecolor.svg',
  },
  [BuzzLogoIdEnum.MATTERMOST_COLOR]: {
    title: 'Mattermost',
    src: 'https://ucarecdn.com/b72df0bf-ed02-424a-80e6-c5afff0ef8fb/mattermostcolor.svg',
  },
  [BuzzLogoIdEnum.MATTERMOST_BW]: {
    title: 'Mattermost',
    src: 'https://ucarecdn.com/ec9a4d99-f252-4594-8074-653a0aafb3ca/mattermostbw.svg',
  },
  [BuzzLogoIdEnum.PAPERLESSPOST]: {
    title: 'Paperless Post',
    src: 'https://ucarecdn.com/962ce139-0778-4144-a12e-d3836c69d36c/paperlesspost.svg',
  },
  [BuzzLogoIdEnum.UNIVERSE]: {
    title: 'Universe',
    src: 'https://ucarecdn.com/8eb771ec-cc68-413c-b1db-49deeab9f257/universe.svg',
  },
  [BuzzLogoIdEnum.UPSCRIBE_BW]: {
    title: 'Upscribe',
    src: 'https://ucarecdn.com/8da786c5-8353-4c1e-bcb7-3b6dc352f70b/upscribebw.svg',
  },
  [BuzzLogoIdEnum.UPSCRIBE_COLOR]: {
    title: 'Upscribe',
    src: 'https://ucarecdn.com/8cadb054-afd5-4725-9ff6-3af8e90bef7e/upscribecolor.svg',
  },
  [BuzzLogoIdEnum.POLISHED]: {
    title: 'Polished',
    src: 'https://ucarecdn.com/248a60d5-8b92-4f0c-ac42-b496836dbd04/polished.svg',
  },
  [BuzzLogoIdEnum.SUPERVISION_ASSIST]: {
    title: 'Supervision Assist',
    src: 'https://ucarecdn.com/05672a86-7bed-4259-a380-5670e3f9be39/supervisionassist.svg',
  },
  [BuzzLogoIdEnum.FACEBOOK_BW]: {
    title: 'Facebook',
    src: 'https://ucarecdn.com/9fcd4481-de10-40e2-94dc-28a835afd9d7/facebookbw.svg',
  },
  [BuzzLogoIdEnum.FACEBOOK_COLOR]: {
    title: 'Facebook',
    src: 'https://ucarecdn.com/1e39ebf2-a267-495b-84f7-cbb00cf9d5e1/facebookcolor.svg',
  },
};
