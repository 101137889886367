import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { logos } from './logos';

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const BuzzLogo = ({ id, width, color, className, offset }) => {
  const logo = logos[id];
  const { component: LogoComponent, src, title = '' } = logo;

  const logoOffset = offset || logo.offset;
  const getOffset = (idx) =>
    logoOffset && typeof logoOffset[idx] !== 'undefined' && `${logoOffset[idx]}%`;

  return (
    <Box
      className={className}
      sx={{
        paddingTop: getOffset(0),
        paddingRight: getOffset(1),
        paddingBottom: getOffset(2),
        paddingLeft: getOffset(3),
        display: 'flex',
        justifyContent: 'center',
        color,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width,
        }}
      >
        {LogoComponent ? <StyledImg as={LogoComponent} /> : <StyledImg src={src} alt={title} />}
      </Box>
    </Box>
  );
};

BuzzLogo.propTypes = {
  id: PropTypes.oneOf(Object.keys(logos)).isRequired,
  width: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  offset: PropTypes.arrayOf(PropTypes.number),
};
