import React from 'react';
import { TextLogo } from './TextLogo';

const AesEncryption = () => {
  return (
    <TextLogo>
      256-BIT AES <br />
      ENCRYPTION
    </TextLogo>
  );
};

export default AesEncryption;
