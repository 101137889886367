export const BuzzLogoIdEnum = {
  AITO_COLOR: 'aito-color',
  AITO_BW: 'aito-bw',
  BUFFER_BW: 'buffer-bw',
  BUFFER_COLOR: 'buffer-color',
  SUNDANCE_COLOR: 'sundance-color',
  SUNDANCE_BW: 'sundance-bw',
  CHATRA: 'chatra',
  CHATRA_COLOR: 'chatra-color',
  IRELAND_COLOR: 'ireland-color',
  IRELAND_BW: 'ireland-bw',
  PHILIPPINES_COLOR: 'philippines-color',
  PHILIPPINES_BW: 'philippines-bw',
  BERKELEY_BW: 'berkeley-bw',
  BERKELEY_COLOR: 'berkeley-color',
  BRITISH_COUNCIL_COLOR: 'britishcouncil-color',
  BRITISH_COUNCIL_BW: 'britishcouncil-bw',
  CARDANO_COLOR: 'cardano-color',
  CARDANO_BW: 'cardano-bw',
  CAMPAIGNMONITOR_BW: 'campaignmonitor-bw',
  CAMPAIGNMONITOR_COLOR: 'campaignmonitor-color',
  CISCO_BW: 'cisco-bw',
  CISCO_COLOR: 'cisco-color',
  CODEMENTOR_BW: 'codementor-bw',
  CODEMENTOR_COLOR: 'codementor-color',
  FITBIT_COLOR: 'fitbit-color',
  FITBIT_BW: 'fitbit-bw',
  FRAMEFOX_BW: 'framefox-bw',
  FRAMEFOX_COLOR: 'framefox-color',
  INDEED_BW: 'indeed-bw',
  INDEED_COLOR: 'indeed-color',
  INSTAMAG_BW: 'instamag-bw',
  INSTAMAG_COLOR: 'instamag-color',
  INSTAMAG_MONO: 'instamag-mono',
  LOREAL: 'loreal',
  MOZILLA: 'mozilla',
  PANDADOC_BW: 'pandadoc-bw',
  PANDADOC_COLOR: 'pandadoc-color',
  PREZLY_BW: 'prezly-bw',
  PREZLY_COLOR: 'prezly-color',
  POLISHED: 'polished',
  SAMSONITE_BW: 'samsonite-bw',
  SAMSONITE_COLOR: 'samsonite-color',
  SFSTATE_BW: 'sfstate-bw',
  SFSTATE_COLOR: 'sfstate-color',
  SHOGUN_BW: 'shogun-bw',
  SHOGUN_COLOR: 'shogun-color',
  SNUGGPRO_BW: 'snuggpro-bw',
  SNUGGPRO_COLOR: 'snuggpro-color',
  SOUNDCLOUD_BW: 'soundcloud-bw',
  SOUNDCLOUD_COLOR: 'soundcloud-color',
  SPLITMETRICS_BW: 'splitmetrics-bw',
  SPLITMETRICS_COLOR: 'splitmetrics-color',
  STACKSHARE_BW: 'stackshare-bw',
  STACKSHARE_COLOR: 'stackshare-color',
  SUPERVISION_ASSIST: 'supervision-assist',
  TEOPIKI: 'teopiki',
  TIMEPAD_COLOR: 'timepad-color',
  TIMEPAD: 'timepad',
  TOTANGO_BW: 'totango-bw',
  TOTANGO_COLOR: 'totango-color',
  YCOMBINATOR_BW: 'ycombinator-bw',
  YCOMBINATOR_COLOR: 'ycombinator-color',
  UNIDESK_BW: 'unidesk-bw',
  UNIDESK_COLOR: 'unidesk-color',
  UNIVERSAL: 'universal',
  UPLOADCARE: 'uploadcare',
  UPLOADCARE_BW: 'uploadcare-bw',
  WEBFLOW: 'webflow',
  ZILLOW_BW: 'zillow-bw',
  ZILLOW_COLOR: 'zillow-color',
  LAPHIL_COLOR: 'laphil-color',
  LAPHIL: 'laphil',
  HIPAA: 'hipaa',
  GDPR: 'gdpr',
  PRIVACY_SHIELD: 'privacy-shield',
  TWO_FA: 'two-fa',
  AES_ENCRYPTION: 'aes-encryption',
  ZEPHYR_BW: 'zephyr-bw',
  ZEPHYR_COLOR: 'zephyr-color',
  CELEARNING_BW: 'ce-learning-bw',
  CELEARNING_COLOR: 'ce-learning-color',
  CINDICATOR: 'cindicator',
  ENGELVOLKERS_BW: 'engelvolkers-bw',
  ENGELVOLKERS_COLOR: 'engelvolkers-color',
  LMBD: 'lmbd',
  METIGY_BW: 'metigy-bw',
  METIGY_COLOR: 'metigy-color',
  MOODZER_BW: 'moodzer-bw',
  MOODZER_COLOR: 'moodzer-color',
  MIGHTYCAUSE_BW: 'mightycause-bw',
  MIGHTYCAUSE_COLOR: 'mightycause-color',
  MATTERMOST_COLOR: 'mattermost-color',
  MATTERMOST_BW: 'mattermost-bw',
  PAPERLESSPOST: 'paperlesspost',
  UNIVERSE: 'universe',
  UPSCRIBE_BW: 'upscribe-bw',
  UPSCRIBE_COLOR: 'upscribe-color',
  FACEBOOK_BW: 'facebook-bw',
  FACEBOOK_COLOR: 'facebook-color',
};
