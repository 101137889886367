import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'rebass/styled-components';
import { BuzzLogo } from './BuzzLogo';

export const BuzzLogoGrid = ({
  ids,
  widths,
  offsets,
  columns,
  logoSx = {},
  containerSx = {},
  className,
}) => {
  return (
    <Box
      className={className}
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        color: '#000000',
        justifyContent: 'space-between',
        ...containerSx,
      }}
    >
      {ids.map((id, idx) => {
        const sx = Array.isArray(logoSx) ? logoSx[idx] : logoSx;

        return (
          <React.Fragment key={`${id}${idx}`}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                minHeight: '50px',
                px: '15px',
                ...sx,
              }}
            >
              <BuzzLogo id={id} width={widths[idx]} offset={offsets && offsets[idx]} />
            </Box>
            {columns && (idx + 1) % columns === 0 && ids.length !== idx - 1 ? (
              <Box as="span" sx={{ flex: '0 0 100%', display: ['block', 'none'] }} />
            ) : null}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

BuzzLogoGrid.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  widths: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  offsets: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  columns: PropTypes.number,
  logoSx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  containerSx: PropTypes.object,
  className: PropTypes.string,
};
