import React from 'react';
import { TextLogo } from './TextLogo';

const TwoFactorAuth = () => {
  return (
    <TextLogo>
      Two-factor <br />
      authentication
    </TextLogo>
  );
};

export default TwoFactorAuth;
